// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define */

import * as React from 'react';

import styled from '@emotion/styled';

import { useSelect } from 'downshift';

import Chevron from '@zego/static/svgs/Chevron';
import Tick from '@zego/static/svgs/Tick.js';
import { BREAKPOINTS, baseType, unit, unitTRBL, white } from '@zego/theme';

import FlexRow from './FlexRow';

const TRANSITION_TIME = 180;
const OPTION_SIZE = 38;

const COLOURS = {
  GREEN: '#83f4b7',
  GREY: '#d2cfd8',
  GREEN_HOVER: '#70cf9b',
  WHITE: '#f2f1f3',
  WHITE_HOVER: '#e8e8e8',
};

export interface Option {
  label: string;
  value: string;
}

interface DropDownProps<OT = Record<string, unknown>> {
  options: Array<Option & OT>;
  children: React.ReactNode;
  buttonCss?: React.CSSProperties | Record<string, React.CSSProperties>;
  isActive: boolean;
  icon?: string;
  onActivate?: () => void;
  onMenuStateChanged?: (open: boolean) => void;
  onChange: (item: { selectedItem: Option & OT }) => void;
}

export default function DropDown<OT>({
  options,
  children,
  buttonCss,
  isActive,
  icon,
  onActivate,
  onMenuStateChanged,
  onChange,
}: DropDownProps<OT>): JSX.Element {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({ items: options, onSelectedItemChange: onChange });

  React.useEffect(() => {
    if (isOpen) {
      onActivate && onActivate();
    }
  }, [isOpen, onActivate]);

  React.useEffect(() => {
    onMenuStateChanged && onMenuStateChanged(isOpen);
  }, [isOpen]);

  return (
    <div
      css={{
        borderRadius: unit(1),
        boxShadow: `0 0 8px 1px rgba(0,0,0,0.125)`,
        height: unit(5),
        transition: `height ${TRANSITION_TIME}ms linear`,
        width: '100%',
        [BREAKPOINTS.smallOnly]: { boxShadow: 'none' },
      }}
    >
      <ToggleButton
        {...getToggleButtonProps()}
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={isOpen}
        isOpen={isOpen}
        css={buttonCss}
        isDefaultActive={isActive}
      >
        <FlexRow
          css={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <FlexRow
            css={{ justifyContent: 'flex-start', alignItems: 'center' }}
          >
            {icon && (
              <img
                css={{ paddingRight: unit(1) }}
                src={icon}
                alt="dropdown icon"
              />
            )}
            {selectedItem?.label || children}
          </FlexRow>
          <Chevron direction={isOpen ? 'up' : 'down'} />
        </FlexRow>
      </ToggleButton>
      <DropDownPanel
        {...getMenuProps()}
        css={{
          borderRadius: unitTRBL(0, 0, 1, 1),
          maxHeight: isOpen ? '100vh' : '0vh',
          width: '100%',
          position: 'absolute',
          left: 0,
          boxShadow: `0 0 8px 1px rgba(0,0,0,0.125)`,
          [BREAKPOINTS.medium]: {
            position: 'initial',
          },
        }}
        isOpen={isOpen}
      >
        {options.map((item, index, ops) => (
          <DropDownOption
            data-testid={`${item.value}-${index}`}
            isOpen={isOpen}
            aria-hidden={!isOpen}
            value={item.value}
            {...getItemProps({
              index,
              item,
            })}
            highlight={index === highlightedIndex}
            first={index === 0}
            last={index === ops.length - 1}
            key={`${item.value}${index}`}
            css={{
              zIndex: 1,
              transform: isOpen
                ? 'translateY(0%)'
                : `translateY(${-1 * (index + 1) * 100}%)`,
            }}
          >
            <OptionRow selected={selectedItem?.label === item.label}>
              {item.label}
            </OptionRow>
          </DropDownOption>
        ))}
      </DropDownPanel>
    </div>
  );
}

/**
 * Utility components
 */

function OptionRow({ children, selected }): JSX.Element {
  return (
    <FlexRow
      css={{
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <span>{children}</span>
      <span>{selected && <Tick color="inherit" />}</span>
    </FlexRow>
  );
}

/**
 * Styled components
 */

interface DropDownPanelProps {
  isOpen: boolean;
}

const DropDownPanel = styled('ul')(
  {
    transition: `opacity ${TRANSITION_TIME * 0.5}ms 100ms linear`,
    display: 'flex',
    flexDirection: 'column',
    ':focus': {
      outline: 0,
    },
  },
  ({ isOpen }: DropDownPanelProps) => ({ opacity: isOpen ? 1 : 0 }),
);

interface DropDownOptionProps {
  last: boolean;
  highlight: boolean;
}

const DropDownOption = styled('li')(
  [
    baseType,
    {
      minHeight: OPTION_SIZE,
      transition: `background-color 250ms ease-in-out, transform ${TRANSITION_TIME}ms linear`,
      padding: unitTRBL(1, 2, 1, 2),
      fontWeight: 100,
    },
  ],
  ({ last, highlight }: DropDownOptionProps) => ({
    backgroundColor: highlight ? COLOURS.GREY : COLOURS.WHITE,
    borderBottomLeftRadius: last ? unit(1) : 'initial',
    borderBottomRightRadius: last ? unit(1) : 'initial',
  }),
);

interface ToggleButtonProps {
  isOpen: boolean;
  isDefaultActive: boolean;
}

const ToggleButton = styled('button')(
  {
    zIndex: 1,
    position: 'relative',
    border: 0,
    padding: unitTRBL(1, 2, 1, 2),
    width: '100%',
    borderRadius: unit(1),
    transition: `background-color 100ms ease-in-out, border-bottom-left-radius ${TRANSITION_TIME}ms 75ms linear, border-bottom-right-radius ${TRANSITION_TIME}ms 75ms linear`,
    boxShadow: `0 3px 4px -1px rgba(0,0,0,0.125)`,
    cursor: 'pointer',
  },
  ({ isOpen, isDefaultActive }: ToggleButtonProps) => ({
    borderBottomLeftRadius: isOpen ? 'initial' : unit(1),
    borderBottomRightRadius: isOpen ? 'initial' : unit(1),
    backgroundColor: isOpen || isDefaultActive ? COLOURS.GREEN : white,
    transition: 'background-color 200ms ease-in-out',
    ':hover': {
      backgroundColor:
        isOpen || isDefaultActive ? COLOURS.GREEN_HOVER : COLOURS.WHITE_HOVER,
    },
  }),
);
