// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';

import { disabledColor } from '@zego/theme';

interface HairlineProps {
  color?: string;
  width?: string | number;
  className?: any;
}

const Hairline = ({ color, width, className }: HairlineProps): JSX.Element => (
  <div
    className={className}
    css={{
      width: `${width || 'auto'}`,
      borderTop: `1.1px solid ${color || disabledColor(4)}`,
    }}
  />
);

export default Hairline;
